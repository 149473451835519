const Jobs = () => import('@/views/website/jobs/Jobs.vue');
const Details = () => import('@/views/website/jobs/details/Details.vue');

export default [
  {
    path: 'jobs',
    meta: {
      label: 'Stellenangebote',
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: Jobs,
      },
      {
        path: ':id',
        meta: {
          label: 'Details',
        },
        component: Details,
      },
    ]
  },
]
