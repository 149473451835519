const ProfileDetails = () => import('@/views/user/profile/Details');
const CompanyDetails = () => import('@/views/user/company/Details');
const CiDetails = () => import('@/views/user/ci/Details');
const Invoices = () => import('@/views/user/invoices/Details');

export default [
  {
    path: 'user',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'profile',
        component: ProfileDetails,
      },
      {
        path: 'company',
        component: CompanyDetails,
      },
      {
        path: 'ci',
        component: CiDetails,
      },
      {
        path: 'invoices',
        component: Invoices,
      },
    ]
  },
]
