import 'core-js/stable'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import CoreuiVue from '@coreui/vue'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import auth from "./auth/authService";
import AuthPlugin from "./auth/auth";
import store from '@/Store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'croppie/croppie.css';
import '@/shared/validators';
import '@/shared/filter';
import '@/shared/prototype';
import 'intro.js/introjs.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Rollbar from 'rollbar';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGtag from "vue-gtag";

Vue.use(BootstrapVue);
Vue.use(CoreuiVue);
Vue.use(AuthPlugin);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: 'places',
  },
});

if(process.env.NODE_ENV === 'production'){
  // Google analytics
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ANALYTICS_ID }
  }, router);

  // setup error handling and logging to remote
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.VUE_APP_ROLLBAR_ACCESSTOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    checkIgnore: function(isUncaught, args, payload) {
      if(
        args[0] === "Network Error" ||
        (args[1] && args[1].stack.indexOf("intro.js") !== -1)
      ){
        return true;
      }
    }
  });
  Vue.config.errorHandler = (err, vm, info) => {
    if(err.name !== 'NavigationDuplicated'){
      let user = store.getters.getUser;
      if(user !== null){
        Vue.prototype.$rollbar.configure({
          payload: {
            person: {
              id: user.id,
              username: user.name,
              email: user.email
            }
          }
        });
      }
      vm.$rollbar.error(err);
    }
  }
}

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === 'test' || to.path === "/" || to.path === "/callback" || auth.isAuthenticated()) {
    return next();
  }
  auth.login({ target: to.path });
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
