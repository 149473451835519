// auth callback
const Callback = () => import('@/auth/Callback');

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const OldSystemFrame = () => import('@/views/OldSystemFrame');
const Dashboard = () => import('@/views/dashboard/Dashboard');

let redirect = '/dashboard';
if(localStorage.getItem('redirect')){
  redirect = localStorage.getItem('redirect');
  localStorage.removeItem('redirect');
}

// Additional route files
import CampRoutes from '@/views/camps/routes.js';
import ControllingRoutes from '@/views/controlling/routes.js';
import CustomerRoutes from '@/views/customers/routes.js';
import DataRoutes from '@/views/data/routes.js';
import EventsRoutes from '@/views/events/routes.js';
import HelpRoutes from '@/views/help/routes.js';
import KmRoutes from '@/views/km/routes.js';
import MarketingRoutes from '@/views/marketing/routes.js';
import ShopRoutes from '@/views/shop/routes.js';
import WebsiteRoutes from '@/views/website/routes.js';
import UserRoutes from '@/views/user/routes.js';

export default [
  {
    path: '/',
    redirect: redirect,
    component: TheContainer,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'clients/overview',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=104'
        }
      },
      {
        path: 'frame/:url',
        name: 'Frame',
        component: OldSystemFrame,
        props:{
          url: ''
        }
      },
      ...CampRoutes,
      ...ControllingRoutes,
      ...CustomerRoutes,
      ...DataRoutes,
      ...EventsRoutes,
      ...HelpRoutes,
      ...KmRoutes,
      ...MarketingRoutes,
      ...ShopRoutes,
      ...WebsiteRoutes,
      ...UserRoutes,
    ]
  },
  {
    path: "/callback",
    component: Callback
  },
]
