import { localize, extend } from 'vee-validate';
import { required,regex,image,alpha_dash,confirmed,numeric,ext,digits,length } from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
localize('de', de);

extend('required', required);
extend('numeric', numeric);
extend('confirmed', confirmed);
extend('regex', regex);
extend('image', image);
extend('ext', ext);
extend('length', length);
extend('alpha_dash', alpha_dash);
extend('digits', digits);
extend('url', {
  validate: (value, args) => {
    let pattern = new RegExp('^(https?:\\/\\/)' + (args.args === 'require_protocol' ? '' : '?') + // protocol
      '(' +
        '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3})|' + // OR ip (v4) address
        '(#userUrl#)' + // OR gecco url variable
      ')' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(value);
  },
  params: ['args'],
  message: '{_field_} muss eine gültige Adresse sein'
});
extend('verify_password', {
  validate: (value, args) => {
    let pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\!\@\#\$\%\^\&\*\?\_\~\§])(?=.{8,})");
    let numbers = (value.match(new RegExp("[0-9]", "g")) || []).length;
    let specialChars = (value.match(new RegExp("[\!\@\#\$\%\^\&\*\?\_\~\§]", "g")) || []).length;
    return !!pattern.test(value) && (numbers >= 3 || specialChars >= 2);
  },
  params: ['args'],
  message: 'Das Passwort muss mind. 8 Zeichen lang sein, Groß- und Kleinbuchstaben sowie mind. 3 Zahlen und/oder 2 Sonderzeichen (z.B. ! @ # $ % ^ & * § ? _ ~) enthalten.'
});
extend('email', {
  validate: (value) => {
    let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([üöäÜÖÄa-zA-Z\-0-9]+\.)+[üöäÜÖÄa-zA-Z]{2,}))$/;
    return pattern.test(String(value));
  },
  message: '{_field_} muss eine gültige E-Mail-Adresse sein'
});
extend('email', {
  validate: (value) => {
    var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([üöäÜÖÄa-zA-Z\-0-9]+\.)+[üöäÜÖÄa-zA-Z]{2,}))$/;
    return pattern.test(String(value));
  },
  message: '{_field_} muss eine gültige E-Mail-Adresse sein'
});

extend('includes', {
  validate(value, args) {
    let pattern = new RegExp('.*' + args.args + '.*'); // fragment locator
    return !!pattern.test(value);
  },
  params: ['args'],
  message: 'Dieses Feld muss {args} enthalten'
});

extend('arr_min_length', {
  validate(value, args) {
    return value.length >= args.args;
  },
  params: ['args'],
  message: '{_field_} muss mindestens {args} Einträge enthalten'
});
