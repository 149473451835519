const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'shop',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'overview',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=308'
        }
      },
      {
        path: 'order',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=455'
        }
      },
      {
        path: 'voucher-account',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=484&func=showCouponAccount'
        }
      },
    ]
  },
]
