const Emails = () => import('@/views/data/settings/emails/Emails');
const Details = () => import('@/views/data/settings/emails/details/Details');

export default [
  {
    path: 'emails',
    name: 'Emails',
    redirect: '/data/settings/emails/',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Emails,
      },
      {
        path: ':id',
        name: 'Details',
        component: Details,
      }
    ]
  },
]
