const News = () => import('@/views/website/news/News.vue');
const Details = () => import('@/views/website/news/details/Details.vue');

export default [
  {
    path: 'news',
    meta: {
      label: 'News'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: News
      },
      {
        path: ':id',
        meta: {
          label: 'Details',
        },
        component: Details
      },
    ]
  },
]
