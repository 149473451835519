import moment from 'moment';
import Vue from 'vue';
import store from '@/Store';
moment.locale('de');

Vue.filter('formatDate', function(value, format = 'DD.MM.YYYY') {
  if (value) {
    return moment(String(value)).format(format)
  }
});

Vue.filter('userUrl', function(value) {
  if (value) {
    let user = store.getters.getUser;
    if(user.user){
      return value.replace('#userUrl#', user.user.url);
    }
  }
});
