const Courses = () => import('@/views/website/courses/Courses.vue');
const Details = () => import('@/views/website/courses/details/Details.vue');

export default [
  {
    path: 'courses',
    meta: {
      label: 'Kurse & Seminare'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Courses
      },
      {
        path: ':id',
        meta: {
          label: 'Details',
        },
        component: Details
      },
    ]
  },
]
