const Forms = () => import('@/views/website/forms/Forms.vue');
const Details = () => import('@/views/website/forms/details/Details.vue');

export default [
  {
    path: 'forms',
    meta: {
      label: 'Formulare'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Forms
      },
      {
        path: ':id',
        meta: {
          label: 'Details',
        },
        component: Details
      },
    ]
  },
]
