const OldSystemFrame = () => import('@/views/OldSystemFrame');
import ConsultationRoutes from '@/views/customers/consultation/routes.js';
import FunlearnRoutes from '@/views/customers/funlearn/routes.js';

export default [
  {
    path: 'customers',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'overview',
        component: OldSystemFrame,
        props:{
          url: '/login/customers'
        }
      },
      {
        path: 'price',
        component: OldSystemFrame,
        props:{
          url: '/login/pricelist'
        }
      },
      {
        path: 'abc',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=347'
        }
      },
      ...ConsultationRoutes,
      ...FunlearnRoutes,
    ]
  },
]
