const OldSystemFrame = () => import('@/views/OldSystemFrame.vue');
const Settings = () => import('@/views/website/settings/Settings.vue');
const Guestbook = () => import('@/views/website/guestbook/Guestbook.vue');

import CmsRoutes from '@/views/website/cms/routes.js';
import BkfRoutes from '@/views/website/bkf/routes.js';
import FormsRoutes from '@/views/website/forms/routes.js';
import CoursesRoutes from '@/views/website/courses/routes.js';
import JobRoutes from '@/views/website/jobs/routes.js';
import GalleryRoutes from '@/views/website/galleries/routes.js';
import NewsRoutes from '@/views/website/news/routes.js';

export default [
  {
    path: 'website',
    meta: {
      label: 'Webseite',
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      ...CmsRoutes,
      ...BkfRoutes,
      ...FormsRoutes,
      ...CoursesRoutes,
      ...JobRoutes,
      ...GalleryRoutes,
      ...NewsRoutes,
      {
        path: 'guestbook',
        component: Guestbook,
        meta: {
          label: 'Kundenstimmen',
        },
      },
      {
        path: 'news',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=38'
        }
      },
      {
        path: 'settings',
        name: 'Einstellungen',
        component: Settings
      },
      {
        path: 'stats',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=28'
        }
      },
    ]
  },
]
