const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'help',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'faq',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=31'
        }
      },
      {
        path: 'tickets',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=56&func=showMyTickets'
        }
      },
      {
        path: 'faq-admin',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=313&func=showAllFAQ'
        }
      },
      {
        path: 'tickets-admin',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=317&func=showMyTickets&employeeID=master'
        }
      },
    ]
  },
]
