import auth0 from "auth0-js";
import { EventEmitter } from "events";

const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  redirectUri: `${window.location.origin}/callback`,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
  responseType: "id_token",
  scope: "openid profile email"
});

const loginEvent = "loginEvent";

class AuthService extends EventEmitter {
  profile = null;

  login(customState) {
    webAuth.authorize({
      appState: customState
    });
  }

  logOut() {
    sessionStorage.removeItem('idToken');
    sessionStorage.removeItem('tokenExpiry');
    this.profile = null;

    webAuth.logout({
      returnTo: `${window.location.origin}`
    });

    this.emit(loginEvent, { loggedIn: false });

    window.location.href = process.env.VUE_APP_OLD_URL + '/auth/logout';
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      webAuth.parseHash((err, authResult) => {
        if (err) {
          reject(err);
        } else {
          this.localLogin(authResult);
          resolve(authResult.idToken);
        }
      });
    });
  }

  isAuthenticated() {
    return (
      sessionStorage.getItem('idToken') &&
      sessionStorage.getItem('tokenExpiry') &&
      Date.now() < parseInt(sessionStorage.getItem('tokenExpiry'))
    );
  }

  getIdToken() {
    return new Promise((resolve, reject) => {
      if (this.isAuthenticated()) {
        resolve(sessionStorage.getItem('idToken'));
      } else {
        this.renewTokens()
          .then(authResult => {
            resolve(authResult.idToken);
          })
          .catch(function () {
            resolve();
          })
      }
    });
  }

  localLogin(authResult) {
    this.profile = authResult.idTokenPayload;
    sessionStorage.setItem('idToken', authResult.idToken);
    sessionStorage.setItem('tokenExpiry', this.profile.exp * 1000);

    this.emit(loginEvent, {
      loggedIn: true,
      profile: authResult.idTokenPayload,
      state: authResult.appState || {}
    });
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      if (!sessionStorage.getItem('idToken') || sessionStorage.getItem('idToken') === null) {
        return reject("Not logged in");
      }

      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          reject(err);
        } else {
          this.localLogin(authResult);
          resolve(authResult);
        }
      });
    });
  }
}

const service = new AuthService();

service.setMaxListeners(5);

export default service;
