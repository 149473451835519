const MediaManager = () => import('@/views/marketing/media-manager/MediaManager');
const Details = () => import('@/views/marketing/media-manager/details/Details.vue');

export default [
  {
    path: 'media-manager',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '/',
        component: MediaManager,
      },
      {
        path: 'copy/:copy_post_id',
        name: 'social_post_details',
        meta: {
          label: 'Details',
        },
        component: Details
      },
      {
        path: ':post_id/:date?',
        name: 'social_post_details',
        meta: {
          label: 'Details',
        },
        component: Details
      },
    ]
  }
]
