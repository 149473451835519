const Galleries = () => import('@/views/website/galleries/Galleries.vue');
const Details = () => import('@/views/website/galleries/details/Details.vue');

export default [
  {
    path: 'galleries',
    meta: {
      label: 'Bildergalerien',
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        component: Galleries,
      },
      {
        path: ':id',
        meta: {
          label: 'Details',
        },
        component: Details,
      },
    ]
  },
]
