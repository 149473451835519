const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'consultation',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'overview',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=444'
        }
      },
      {
        path: 'settings',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=447&func=consultationSettings'
        }
      },
    ]
  },
]
