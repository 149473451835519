const Usp = () => import('@/views/data/usp/Usp.vue');
const Details = () => import('@/views/data/usp/details/Details.vue');

export default [
  {
    path: 'usp',
    meta: {
      label: 'Alleinstellungsmerkmale'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '/',
        component: Usp
      },
      {
        path: ':type/:id',
        meta: {
          label: 'Details',
        },
        component: Details
      },
    ]
  },
]
