const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'camps',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'fcamp',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'course',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=14'
            }
          },
          {
            path: 'welcome',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=273&func=welcomeLetter'
            }
          },
          {
            path: 'phone',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=140&func=fcPackets'
            }
          },
          {
            path: 'contract',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=288&func=trainingContract'
            }
          },
          {
            path: 'hotline',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=171'
            }
          },
        ]
      },
      {
        path: 'mcamp',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'course',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=263'
            }
          },
          {
            path: 'welcome',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=268&func=welcomeLetter'
            }
          },
          {
            path: 'phone',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=265&func=mcPackets'
            }
          },
          {
            path: 'contract',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=289&func=trainingContract'
            }
          },
          {
            path: 'hotline',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=269'
            }
          },
        ]
      },
    ]
  },
]
