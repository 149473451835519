const Template = () => import('@/views/website/cms/template/Template');
const Language = () => import('@/views/website/cms/language/Language');
const Page = () => import('@/views/website/cms/page/Page');
const Cms = () => import('@/views/website/cms/Cms');

export default [
  {
    path: 'cms',
    name: 'Homepage',
    redirect: 'cms/0',
    component: Cms,
    children: [
      {
        path: ':template',
        component: Template,
        children: [
          {
            path: ':language',
            component: Language,
            children: [
              {
                path: ':id',
                component: Page,
              },
              {
                path: 'new/:parent_id',
                component: Page,
              },
            ]
          },
        ]
      },
    ]
  },
]
