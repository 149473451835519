<script>
import Vue from 'vue'
import Vuex from 'vuex'
import Http from './shared/http-config'
import {isMobile} from '@/shared/utils.js'

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,

  modules: {
    // dynamic injection
  },

  strict: process.env.NODE_ENV !== 'production',

  state () {
    return {
      sidebarShow: 'responsive',
      sidebarMinimize: false,
      asideShow: false,
      darkMode: false,
      user: null,
      pendingRequests: [],
      introJsEnabled: true,
      errorModal: false,
      errorMessage: '',
      errorData: {},
      sidebarNav: [],
      listView: isMobile(),
    }
  },

  getters: {
    get: (state) => (variable) => {
      return state[variable];
    },
    isLoading: state => {
      return state.pendingRequests.length > 0
    },
    getErrorMessage: state => {
      return state.errorMessage
    },
    getErrorData: state => {
      return state.errorData
    },
    getUser: state => {
      return state.user
    },
    getErrorModal: state => {
      return state.errorModal
    },
    getSidebarNav: state => {
      return state.sidebarNav
    },
    getIntroJsEnabled: state => {
      return state.introJsEnabled
    }
  },

  mutations: {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
        state[variable] = value
    },
    toggle (state, variable) {
        state[variable] = !state[variable]
    },
    ADD_REQUEST(state, url) {
      state.pendingRequests.push(url);
    },
    DELETE_REQUEST(state) {
      state.pendingRequests.pop();
    },
    SET_USER(state, user) {
      Vue.set(state, 'user', user);
    },
    SET_ERROR_MODAL(state, value) {
      Vue.set(state, 'errorModal', value);
    },
    SET_ERROR_DATA(state, value) {
      Vue.set(state, 'errorData', value);
    },
    SET_ERROR_MESSAGE(state, value) {
      Vue.set(state, 'errorMessage', value);
    },
    SET_SIDEBAR_NAV(state, value) {
      Vue.set(state, 'sidebarNav', value);
    },
    SET_INTRO_JS_ENABLED(state, value) {
      Vue.set(state, 'introJsEnabled', value);
    }
  },

  actions: {
    setIntroJsEnabled(context, url) {
      context.commit('SET_INTRO_JS_ENABLED', url);
    },
    addRequest(context, url) {
      context.commit('ADD_REQUEST', url);
    },
    deleteRequest(context) {
      context.commit('DELETE_REQUEST');
    },
    async fetchUser(context, force = false){
      if(context.state.user === null || force) {
        return await Http.request('get', '/user')
          .then((user) => {
            context.commit('SET_USER', user.data);
          })
      }
      return await new Promise((resolve) => {
        resolve(context.state.user);
      });
    },
    setErrorModal(context, value){
      context.commit('SET_ERROR_MODAL', value);
      if(value === false){
        context.commit('SET_ERROR_DATA', []);
        context.commit('SET_ERROR_MESSAGE', '');
      }
    },
    setErrorData(context, data){
      context.commit('SET_ERROR_DATA', data);
    },
    throwErrorMessage(context, value) {
      context.commit('SET_ERROR_MESSAGE', value);
      context.commit('SET_ERROR_MODAL', true);
    },
    setSidebarNav(context, value) {
      context.commit('SET_SIDEBAR_NAV', value);
    },
  }
});
</script>
