const OldSystemFrame = () => import('@/views/OldSystemFrame');
import SettingsRoutes from '@/views/data/settings/routes.js';
import EmployeeRoutes from '@/views/data/employees/routes.js';
import UspRoutes from '@/views/data/usp/routes.js';

export default [
  {
    path: '/data/',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'classes',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=241'
        }
      },
      {
        path: 'branches',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=280'
        }
      },
      {
        path: 'team/team',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=36'
        }
      },
      {
        path: 'team/survey',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=370&func=employeeSurveyDriverSchool'
        }
      },
      {
        path: 'cars',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=34'
        }
      },
      ...SettingsRoutes,
      ...EmployeeRoutes,
      ...UspRoutes
    ]
  },
]
