const OldSystemFrame = () => import('@/views/OldSystemFrame');
import EmailsRoutes from '@/views/data/settings/emails/routes.js';

export default [
  {
    path: 'settings',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'domain',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=47'
        }
      },
      ...EmailsRoutes
    ]
  }
]
