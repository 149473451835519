const Events = () => import('@/views/events/Events.vue');
const Details = () => import('@/views/events/details/Details.vue');

export default [
  {
    path: 'events',
    meta: {
      label: 'Events'
    },
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'overview',
        meta: {
          label: 'Übersicht'
        },
        component: Events,
      },
      {
        path: 'booked',
        name: 'booked',
        meta: {
          label: 'Deine Buchungen'
        },
        component: Events,
      },
      {
        path: ':id',
        meta: {
          label: 'Details'
        },
        component: Details,
      },
    ]
  },
]
