<template>
  <div>
    <template v-if="isIE">
      <div style="width: 100%; height: 100%; text-align: center;">
        <br><br>
        <h2 style="color: red;">Achtung:</h2>
        <p>Um eine optimale Darstellung des Login Bereiches zu gew&auml;hrleisten, empfehlen wir,<br> die jeweils aktuellste Version folgender Browser zu verwenden:</p>
        <ul style="list-style-type: none;">
          <li><a target="_new" href="https://www.google.com/chrome/">Chrome für PC (Download hier)</a></li>
          <li><a target="_new" href="https://www.mozilla.org/de/firefox/">Firefox für PC & Mac (Download hier)</a></li>
        </ul>
        <p>Der Internet Explorer wird erst ab "Edge" unterstützt!</p>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
      <div v-bind:style="{ display: isLoading ? 'block' : 'none' }">
        <div class="modal-backdrop fade show"></div>
        <img id="loading" src="/brand/logo-symbol.svg" width="150" alt="Gecco Logo">
      </div>
      <b-modal title="Fehler"
               header-bg-variant="primary"
               class="modal-danger"
               id="requestFailureModal"
               v-model="requestFailureModal"
               @hidden="closeRequestFailureModal()"
               ok-variant="primary"
               :ok-only="true">
        <div v-html="errorMessage"></div>
        <template v-if="errorData && Object.keys(errorData).length > 0">
          <p></p>
          <ul style="padding-left: 20px;">
            <li v-for="(value, name, index) in errorData" v-bind:key="index">
              <template v-for="text in value">{{ text }}</template>
            </li>
          </ul>
        </template>
      </b-modal>
    </template>
  </div>
</template>

<script>
export default {
  name: 'App',
  created: function(){
    let self = this;
    /* istanbul ignore next */
    window.addEventListener("message", function (e) {
        if(e.origin !== process.env.VUE_APP_OLD_URL && e.origin !== window.location.origin) { return; }
        if(e.data === "unauthenticated"){
          localStorage.setItem('redirect', window.location.pathname);
          window.location.href = process.env.VUE_APP_OLD_URL + '/login/authenticate';
        }
        if(e.data === "impersonate"){
          self.$router.push("/dashboard");
          location.reload();
        }
      },false);
  },
  computed: {
    isIE () {
      let ua = navigator.userAgent;
      return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    },
    isLoading () {
      return this.$store.getters.isLoading
    },
    errorMessage () {
      return this.$store.getters.getErrorMessage
    },
    errorData () {
      return this.$store.getters.getErrorData
    },
    requestFailureModal: {
      get () {
        return this.$store.getters.getErrorModal
      },
      set () {
        this.$store.dispatch('setErrorModal', false);
      }
    }
  },
  methods: {
    closeRequestFailureModal () {
      this.$store.dispatch('setErrorModal', false)
    }
  }
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/free.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.min.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
<style>
  #loading {
    position: fixed;
    z-index: 9999;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    top: 250px;
    bottom: 0;
    left: 50%;
    right: 0;

    -webkit-animation-name: spin;
    -webkit-animation-duration: 4000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 4000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 4000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(360deg); }
  }
  @-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
</style>
