export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    let temp = array[i];
    array[i] = array[j];
    array[j] = temp
  }
  return array
};

export const formatActive = function (value) {
  if (value) {
    return 'online'
  }
  else{
    return 'offline'
  }
};

export const isMobile = function () {
  return (window.innerWidth < 720);
};

export const nl2br = function (str, isXhtml) {
  if (typeof str === 'undefined' || str === null) {
    return ''
  }
  let breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br ' + '/>' : '<br>'
  return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, breakTag + '$1')
}
