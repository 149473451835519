const OldSystemFrame = () => import('@/views/OldSystemFrame');

export default [
  {
    path: 'employees',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'overview',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=244'
        }
      },
      {
        path: 'permissions',
        component: OldSystemFrame,
        props:{
          url: '/login/permissions'
        }
      },
    ]
  }
]
