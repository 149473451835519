const OldSystemFrame = () => import('@/views/OldSystemFrame');

import MediaManager from '@/views/marketing/media-manager/routes.js';

export default [
  {
    path: 'marketing',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      ...MediaManager,
      {
        path: 'store-window',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'overview',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=15'
            }
          },
          {
            path: 'storewindow-pages',
            component: OldSystemFrame,
            props:{
              url: '/login/storewindow_pages'
            }
          },
        ]
      },
      {
        path: 'pr',
        component: {
          render (c) { return c('router-view') }
        },
        children: [
          {
            path: 'overview',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=25'
            }
          },
          {
            path: 'distributor',
            component: OldSystemFrame,
            props:{
              url: '/login/templates/content.php?id=50&func=presseVerteiler'
            }
          },
        ]
      },
      {
        path: 'society',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=434'
        }
      },
      {
        path: 'mailings',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=24'
        }
      },
      {
        path: 'newsletter',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=26'
        }
      },
      {
        path: 'feedback-evaluation',
        component: OldSystemFrame,
        props:{
          url: '/login/templates/content.php?id=27'
        }
      },
    ]
  },
]
